import React, { useEffect } from 'react'
import { navigate } from 'gatsby'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import tw, { styled } from 'twin.macro'
import FormikErrorFocus from '../components/FormikErrorFocus'
import { guardianInitialValues, guardianValidationSchema } from '../forms'
import { guardianTestValues } from '../forms/testData'

// Components
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles'
import {
  FormHeader,
  FormContainer,
  FormSection,
  FormFooter,
  FormControl,
  Row,
  InputError,
  input,
  label,
  styledCheckbox,
} from '../components/FormControls'
import Button from '../components/Button'
import PlacesAutocomplete from '../components/PlacesAutocomplete'
import useFetch from '../hooks/useFetch'

// Set the initial values for the form, fill with test values if the environment DEBUG=forms is set
let initialValues = guardianInitialValues
if (process.env.DEBUG && process.env.DEBUG.split(',').find(el => el === 'forms'))
  initialValues = { ...guardianInitialValues, ...guardianTestValues }

const getInitialValues = passedValues => {
  let initialValues = guardianInitialValues
  if (process.env.DEBUG && process.env.DEBUG.split(',').find(el => el === 'forms'))
    initialValues = { ...guardianInitialValues, ...guardianTestValues }

  if (passedValues?.email) {
    initialValues.guardian.email = passedValues.email
  }

  return initialValues
}

const pageTitle = `Parent/Guardian Information`

/** This is our callback function for the PlacesAutocomplete component
 * It's called once the geolocation data is ready
 */
const handleGeoData = formik => data => {
  formik.setFieldValue('guardian.address.formattedAddress', data.formattedAddress)
  formik.setFieldValue('guardian.address.coordinates', [data.coordinates.lng, data.coordinates.lat])
  formik.setFieldValue('guardian.address.street', data.street)
  formik.setFieldValue('guardian.address.city', data.city)
  formik.setFieldValue('guardian.address.province', data.province)
  formik.setFieldValue('guardian.address.postal', data.postal)
}

/**
 * Stop enter submitting the form.
 * @param keyEvent Event triggered when the user presses a key.
 */
const handleKeyDown = keyEvent => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

const successCallback = res => {
  console.log('RESULTS', res)
  navigate('/confirm-email/', { state: res })
}

const GuardianInfoPage = ({ location }) => {
  const { state } = location
  const { setFetchOptions, isLoading } = useFetch()

  console.log(location)

  const handleSubmit = async body => {
    if (!body.guardian.address.coordinates) {
      delete body.guardian.address.coordinates
    }
    if (!body.guardian.address.formattedAddress) {
      delete body.guardian.address.formattedAddress
    }
    console.log('Form Submitting', body)
    setFetchOptions({
      method: 'POST',
      endpoint: '/.netlify/functions/create-guardian',
      body,
      successCallback,
    })
  }

  return (
    <Layout hideSteps>
      <SEO title={pageTitle} />
      <Container>
        <Formik
          initialValues={getInitialValues(state?.response?.data)}
          validationSchema={guardianValidationSchema}
          onSubmit={handleSubmit}
        >
          {formikProps => (
            <Form onKeyDown={handleKeyDown} name="register" noValidate autoComplete="off">
              <FormHeader>
                <h2>{pageTitle}</h2>
                <p>Please provide the following information. Fields marked with an * are required.</p>
              </FormHeader>
              <div hidden aria-hidden="true">
                <label htmlFor="human">
                  Don’t fill this out if you're human:
                  <Field id="human" name="botField" />
                </label>
              </div>

              <FormContainer>
                <FormSection>
                  <Row tw="flex">
                    <FormControl tw="w-full sm:w-1/2 sm:pr-2">
                      <label css={label} htmlFor="guardianFirst">
                        First Name *
                      </label>
                      <Field id="guardianFirst" name="guardian.firstName" type="text" required css={input} />
                      <ErrorMessage name="guardian.firstName" component={InputError} />
                    </FormControl>

                    <FormControl tw="w-full sm:w-1/2 sm:pl-2">
                      <label css={label} htmlFor="guardianLast">
                        Last Name *
                      </label>
                      <Field id="guardianLast" name="guardian.lastName" type="text" css={input} required />
                      <ErrorMessage name="guardian.lastName" component={InputError} />
                    </FormControl>
                  </Row>

                  <Row>
                    <FormControl tw="w-full">
                      <label css={label} htmlFor="guardianEmail">
                        Email Address *
                      </label>
                      <Field
                        id="guardianEmail"
                        name="guardian.email"
                        type="email"
                        placeholder="me@example.com"
                        css={input}
                        required
                      />
                      <ErrorMessage name="guardian.email" component={InputError} />
                    </FormControl>
                  </Row>

                  <Row tw="flex">
                    <FormControl tw="w-full sm:w-1/3 sm:pr-2">
                      <label css={label} htmlFor="guardianPhone">
                        Phone Number *
                      </label>
                      <Field
                        id="guardianPhone"
                        name="guardian.phone"
                        type="tel"
                        placeholder="(647) 123-1234"
                        pattern="([0-9]{3}) [0-9]{3}-[0-9]{4}"
                        css={input}
                        required
                      />
                      <ErrorMessage name="guardian.phone" component={InputError} />
                    </FormControl>
                    <FormControl tw="w-full sm:w-auto sm:pl-2">
                      <label css={label} htmlFor="guardianBirthday">
                        Date of Birth * <span tw="text-xs font-normal">(YYYY-MM-DD)</span>
                      </label>
                      <Field
                        id="guardianBirthday"
                        name="guardian.birthday"
                        type="date"
                        css={input}
                        placeholder="yyyy-mm-dd"
                        required
                      />
                      <ErrorMessage name="guardian.birthday" component={InputError} />
                    </FormControl>
                  </Row>
                </FormSection>
                <FormSection>
                  <h3 tw="text-purple font-bold mb-6 block text-lg">Home Address</h3>

                  <Field type="hidden" name="guardian.address.formattedAddress" />
                  <Field type="hidden" name="guardian.address.coordinates" />

                  <Row tw="flex">
                    <FormControl tw="w-full sm:w-3/4 sm:pr-2">
                      <label css={label} htmlFor="guardianStreet">
                        Street Address *
                      </label>
                      <PlacesAutocomplete
                        id="guardianStreet"
                        name="guardian.address.street"
                        value={formikProps.values.guardian.address.street}
                        css={input}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        onGeoData={data => {
                          handleGeoData(formikProps)(data)
                        }}
                      />
                      <ErrorMessage name="guardian.address.street" component={InputError} />
                    </FormControl>

                    <FormControl tw="w-full sm:w-1/4 sm:pl-2">
                      <label css={label} htmlFor="guardianunit">
                        Unit/Apt No.
                      </label>
                      <Field id="guardianunit" name="guardian.address.unit" type="text" css={input} />
                      <ErrorMessage name="guardian.address.unit" component={InputError} />
                    </FormControl>
                  </Row>

                  <Row tw="flex">
                    <FormControl tw="w-full sm:w-1/3 sm:pr-2">
                      <label css={label} htmlFor="guardianCity">
                        City *
                      </label>
                      <Field id="guardianCity" name="guardian.address.city" type="text" css={input} required />
                      <ErrorMessage name="guardian.address.city" component={InputError} />
                    </FormControl>

                    <FormControl tw="w-full sm:w-1/3 sm:pl-2 sm:pr-2">
                      <label css={label} htmlFor="guardianProvince">
                        Province *
                      </label>
                      <Field
                        id="guardianProvince"
                        name="guardian.address.province"
                        type="text"
                        css={input}
                        placeholder="ON"
                        required
                      />
                      <ErrorMessage name="guardian.address.province" component={InputError} />
                    </FormControl>

                    <FormControl tw="w-1/2 sm:w-1/3 sm:pl-2">
                      <label css={label} htmlFor="guardianPostal">
                        Postal Code *
                      </label>
                      <Field id="guardianPostal" name="guardian.address.postal" type="text" css={input} required />
                      <ErrorMessage name="guardian.address.postal" component={InputError} />
                    </FormControl>
                  </Row>
                </FormSection>
                {/* <FormSection>
                  <h3 tw="text-purple font-bold mb-6 block text-base">
                    Please indicate if any of the following statements apply to you:
                  </h3>
                  <FormControl tw="flex">
                    <Field
                      id="umbrellaEmployee"
                      type="checkbox"
                      name="guardian.umbrellaEmployee"
                      css={styledCheckbox}
                    />
                    <label tw="ml-3" css={label} htmlFor="umbrellaEmployee">
                      I am a current Umbrella staff member
                    </label>
                  </FormControl>

                  <FormControl tw="mt-6 flex">
                    <Field
                      id="usedEmergencyCare"
                      type="checkbox"
                      name="guardian.usedEmergencyCare"
                      css={styledCheckbox}
                    />
                    <label tw="ml-3" css={label} htmlFor="usedEmergencyCare">
                      I have been utilizing emergency child care during the COVID-19 closure
                    </label>
                  </FormControl>

                  <FormControl tw="mt-6 flex">
                    <Field
                      id="worksOutsideHome"
                      type="checkbox"
                      name="guardian.worksOutsideHome"
                      css={styledCheckbox}
                    />
                    <label tw="ml-3" css={label} htmlFor="worksOutsideHome">
                      I have been required to return to work outside the home
                    </label>
                  </FormControl>
                </FormSection> */}

                <FormSection>
                  <Row>
                    <FormControl tw="flex">
                      <Field id="subscribe" type="checkbox" name="guardian.subscribe" css={styledCheckbox} />
                      <label tw="ml-3" css={label} htmlFor="subscribe">
                        Please keep me updated on Umbrella Family news and events
                      </label>
                    </FormControl>
                  </Row>
                </FormSection>
              </FormContainer>

              <FormFooter>
                <Button to="/" isClear tw="mr-2">
                  Cancel
                </Button>

                <Button isPrimary type="submit" isLoading={isLoading}>
                  Next Step
                </Button>
              </FormFooter>
              <FormikErrorFocus />
            </Form>
          )}
        </Formik>
      </Container>
    </Layout>
  )
}

export default GuardianInfoPage
